import { ChartProps } from '@/components/charts/Chart';
import { DisplayOption } from '@/components/home/DisplayDropdown';
import { FilterKey, FiltersType } from '@/types/filter.type';
import { SgwtWebAnalyticsHTMLElement } from '@/types/sgwt-widgets';
import { Theme } from '@/utils/theme';

const sgwtWebAnalyticsWidget = () =>
  document.querySelector<SgwtWebAnalyticsHTMLElement>('sgwt-web-analytics')!;

const category = 'DashboardPage';
const clickAction = 'Click';
const selectAction = 'Select';

type AgGridAction =
  | 'ColumnPinned'
  | 'ColumnResized'
  | 'ColumnRowGroupChanged'
  | 'GridColumnsChanged'
  | 'SortChanged'
  | 'FilterChanged';

export const logger = {
  trackExport: (): void =>
    sgwtWebAnalyticsWidget().trackEvent(category, clickAction, 'export.button'),
  trackDisplay: (value: DisplayOption): void =>
    sgwtWebAnalyticsWidget().trackEvent(
      category,
      selectAction,
      `display.dropdown - ${value}`,
    ),
  trackClearAll: (): void =>
    sgwtWebAnalyticsWidget().trackEvent(
      category,
      clickAction,
      'clear-all.filters.button',
    ),
  trackClearFilter: (filter: FilterKey): void =>
    sgwtWebAnalyticsWidget().trackEvent(
      category,
      clickAction,
      `clear.filter.badge - ${filter}`,
    ),
  trackWidgetClick: (widgetId: ChartProps['id']): void =>
    sgwtWebAnalyticsWidget().trackEvent(
      category,
      clickAction,
      `widget.chart - ${widgetId}`,
    ),
  trackChangeTheme: (theme: Theme): void =>
    sgwtWebAnalyticsWidget().trackEvent(
      category,
      selectAction,
      `sg-bootstrap.theme-switch - ${theme}`,
    ),
  trackEnableFilter: (filter: FilterKey, enable: boolean): void =>
    sgwtWebAnalyticsWidget().trackEvent(
      category,
      selectAction,
      `left-panel.settings.filter.enable  filter: ${filter}, enable: ${enable}`,
    ),
  trackRuntimeError: (errorMessage: string): void =>
    sgwtWebAnalyticsWidget().trackEvent(
      category,
      'Error',
      `error-boundary.uncatched-error - ${errorMessage}`,
      errorMessage,
    ),
  trackPanelClearFilter: (filter: FilterKey): void =>
    sgwtWebAnalyticsWidget().trackEvent(
      category,
      clickAction,
      `left-panel.filter.clear - ${filter}`,
    ),
  trackSelectFilter: (
    filter: FilterKey,
    filterValue: FiltersType[FilterKey],
  ): void =>
    sgwtWebAnalyticsWidget().trackEvent(
      category,
      clickAction,
      `left-panel.filter.select - filter: ${filter}, value: ${JSON.stringify(
        filterValue,
      )}`,
    ),
  trackAgGridUpdate: (action: AgGridAction): void =>
    sgwtWebAnalyticsWidget().trackEvent(category, action, 'ag-grid.update'),
};
